






























































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { GraphData } from '@/views/taint/types/search'
import Dagre from '@/components/G6/Dagre.vue'
import { formatTimestamp, downloadJson } from '@/utils/utils'

@Component({ name: 'SearchCard', components: { Dagre } })
export default class SearchCard extends VueBase {
  @Prop() info!: any
  @Prop() showGraph: boolean | undefined
  @Prop() isApi: boolean | undefined
  formatTimestamp(time: number) {
    if (time) {
      return formatTimestamp(time)
    } else {
      return ''
    }
  }
  private isEdit = false
  private reqStr = ''
  private resStr = ''
  private buttonLoading = false
  created() {
    this.reqStr =
      (this.info.method_pools.req_header_fs_highlight ||
        this.info.method_pools.req_header_fs ||
        '') +
      '\n\n' +
      (this.info.method_pools.req_data_highlight ||
        this.info.method_pools.req_data ||
        '')

    this.resStr =
      (this.info.method_pools.res_header_highlight ||
        this.info.method_pools.res_header ||
        '') +
      '\n\n' +
      (this.info.method_pools.res_body_highlight ||
        this.info.method_pools.res_body ||
        '')
  }
  get req() {
    return this.reqStr
      .split(`\n`)
      .join('<br/>')
      .split(`*`)
      .join('\\*')
      .replace(new RegExp('\<em\>', 'gi'), '\<tt\>')
      .replace(new RegExp('\<\/em\>', 'gi'), '\</tt\>')
  }

  private levelClass(i: number) {
    const levelArr = ['important', 'height', 'middle', 'low']
    const titleArr = [
      this.$t('views.search.important'),
      this.$t('views.search.height'),
      this.$t('views.search.middle'),
      this.$t('views.search.low'),
    ]
    return { level: levelArr[i - 1], title: titleArr[i - 1] }
  }

  private onCopy() {
    this.$message({
      showClose: true,
      message: this.$t('views.search.copySuccess') as string,
      type: 'success',
    })
  }

  private onError() {
    this.$message({
      showClose: true,
      message: this.$t('views.search.copyFail') as string,
      type: 'error',
    })
  }

  get res() {
    return this.resStr
      .split(`\n`)
      .join('<br/>')
      .split(`*`)
      .join('\\*')
      .replace(new RegExp('\<em\>', 'gi'), '\<tt\>')
      .replace(new RegExp('\<\/em\>', 'gi'), '\</tt\>')
  }

  get copyRes() {
    return this.info.method_pools.res_header + this.info.method_pools.res_body
  }

  private goProject(id: any) {
    if (id) {
      this.$router.push('/project/appDetail/' + id)
    }
  }
  private toVuln(id: any) {
    if (id) {
      this.$router.push('/project/leakDetail/1/' + id)
    }
  }
  private async getMethodPool(
    isReplay?: boolean,
    replay_id?: number,
    method_pool_replay_id?: number
  ) {
    const res = await this.services.taint.graph({
      replay_id: this.isApi ? replay_id : undefined,
      replay_type: this.isApi ? 3 : undefined,
      method_pool_replay_id: this.isApi ? method_pool_replay_id : undefined,
      method_pool_id: this.info.method_pools.id,
      method_pool_type: isReplay ? 'replay' : 'normal',
    })
    if (res.status === 201) {
      this.graphData = res.data
    }
  }
  private activeKey = 'first'
  private graphData: GraphData = {
    nodes: [],
    edges: [],
  }
  private changeActiveKey(e: any) {
    if (e.name === 'second' && !this.graphData.nodes[0]) {
      this.getMethodPool()
    }
  }
  private goPath(url: any) {
    window.open(url)
  }

  private timer: any

  @Watch('$route', { deep: true })
  onRouteChange() {
    clearInterval(this.timer)
  }

  beforeDestroy() {
    clearInterval(this.timer)
  }

  private async send() {
    const str = this.reqStr.replaceAll('&lt;', '<')
    this.loadingStart()
    const res = await this.services.taint.replay({
      methodPoolId: this.isApi
        ? this.info.method_pools.id
        : this.$route.params.id,
      agent_id:
        this.info.method_pools.id > -1 ? undefined : this.info.relations.agent,
      replayRequest: str,
      replay_type: this.isApi ? 3 : undefined,
    })
    this.loadingDone()
    if (res.status !== 201) {
      this.$message({
        type: 'error',
        message: res.msg,
        showClose: true,
      })
      return
    }
    this.buttonLoading = true
    this.timer = setInterval(async () => {
      if (this.isApi) {
        res.data.replay_type = 3
      }
      const resT = await this.services.taint.getReplay(res.data)
      if (resT.status === 201) {
        this.resStr = resT.data.response
        this.graphData = {
          nodes: [],
          edges: [],
        }
        this.$nextTick(async () => {
          await this.getMethodPool(
            true,
            res.data.replayId,
            resT.data.method_pool_replay_id
          )
          this.info.method_pools.id = resT.data.method_pool_replay_id
          this.$forceUpdate()
        })
        clearInterval(this.timer)
        this.buttonLoading = false
      } else if (resT.status === 203) {
        clearInterval(this.timer)
        this.buttonLoading = false
      }
    }, 5000)
  }

  download() {
    const data = this.info.method_pools;
    downloadJson(data)
  }
}
